// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-login-open-js": () => import("./../../../src/pages/login/open.js" /* webpackChunkName: "component---src-pages-login-open-js" */),
  "component---src-pages-my-licenses-index-js": () => import("./../../../src/pages/my-licenses/index.js" /* webpackChunkName: "component---src-pages-my-licenses-index-js" */),
  "component---src-pages-my-licenses-request-index-js": () => import("./../../../src/pages/my-licenses/request/index.js" /* webpackChunkName: "component---src-pages-my-licenses-request-index-js" */),
  "component---src-pages-my-licenses-request-multiple-js": () => import("./../../../src/pages/my-licenses/request/multiple.js" /* webpackChunkName: "component---src-pages-my-licenses-request-multiple-js" */),
  "component---src-pages-my-profile-js": () => import("./../../../src/pages/my-profile.js" /* webpackChunkName: "component---src-pages-my-profile-js" */),
  "component---src-pages-my-tokens-js": () => import("./../../../src/pages/my-tokens.js" /* webpackChunkName: "component---src-pages-my-tokens-js" */),
  "component---src-pages-onboarding-js": () => import("./../../../src/pages/onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-templates-markdown-template-js": () => import("./../../../src/templates/markdownTemplate.js" /* webpackChunkName: "component---src-templates-markdown-template-js" */)
}

