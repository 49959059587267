import * as React from "react"
import { Provider } from "react-redux"

import { tryLoggingUser } from "./src/actions"
import store from "./src/store"
import piwik from "./src/config/piwik"
import setupSentry from "./src/config/sentry"

export const onClientEntry = () => {
  setupSentry()
  if (areCookiesEnabled()) {
    store.dispatch(tryLoggingUser())
  } else {
    if (!window.location.pathname.includes("/please-enable-cookies")) {
      window.location.pathname = "/please-enable-cookies"
    }
  }
}

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>{element}</Provider>
)

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <div data-testid="REACT_READY" />
    </>
  )
}

export const onRouteUpdate = function ({ location }) {
  piwik.track(location)
}

function areCookiesEnabled() {
  let cookieEnabled = navigator.cookieEnabled
  if (!cookieEnabled) {
    document.cookie = "testcookie"
    cookieEnabled = document.cookie.indexOf("testcookie") !== -1
  }
  return cookieEnabled
}
