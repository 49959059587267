import PiwikReactRouter from "piwik-react-router"

import ENVIRONMENT from "./environment"

const piwik = (() => {
  const { piwik: piwikConfig } = ENVIRONMENT
  if (piwikConfig) {
    return PiwikReactRouter({
      url: piwikConfig.url,
      siteId: piwikConfig.siteId,
    })
  } else {
    return {
      track: () => {},
      push: () => {},
    }
  }
})()

piwik.push(["requireConsent"])
piwik.push(["setConsentGiven"])
piwik.push(["trackPageView"])

export default piwik
