import {
  createStore as reduxCreateStore,
  compose,
  applyMiddleware,
} from "redux"
import thunk from "redux-thunk"
import rootReducer from "../reducers"

const createStore = () =>
  reduxCreateStore(rootReducer, compose(applyMiddleware(thunk)))

export default createStore()
