const initialState = {
  loaded: false,
  connected: false,
  admin: false,
  email: null,
  acceptedConditions: false,
  firstName: null,
  lastName: null,
  usageType: null,
  interests: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTED":
      return {
        ...state,
        loaded: true,
        connected: true,
        admin: action.admin,
        email: action.email,
        acceptedConditions: action.acceptedConditions,
        firstName: action.firstName,
        lastName: action.lastName,
        usageType: action.usageType,
        interests: action.interests,
      }
    case "ONBOARDED": {
      return {
        ...state,
        acceptedConditions: true,
        firstName: action.firstName,
        lastName: action.lastName,
        usageType: action.usageType,
        interests: action.interests,
      }
    }
    case "DISCONNECTED":
      return { ...initialState, loaded: true }
    default:
      return state
  }
}
