import Raven from "raven-js"

import ENVIRONMENT from "./environment"

export default function setupSentry() {
  const { sentry } = ENVIRONMENT

  if (sentry !== null) {
    const releaseDate = process.env.GATSBY_RELEASE_DATE
    const commitId = process.env.GATSBY_COMMIT_ID
    if (!releaseDate || !commitId) {
      throw new Error("GATSBY_RELEASE_DATE, GATSBY_COMMIT_ID must be provided")
    }
    const { name: environment } = ENVIRONMENT
    const release = `${releaseDate}_${commitId}`
    const config = {
      release,
      environment,
      tags: {
        releaseDate,
        commitId,
      },
    }
    Raven.config(sentry.dsn, config).install()
  }
}
