import { navigate } from "gatsby"

const KEY_STORAGE = "khiops_authenticated"

export function connected(payload) {
  return {
    type: "CONNECTED",
    ...payload,
  }
}

export function disconnected() {
  return {
    type: "DISCONNECTED",
  }
}

export function onboarded(payload) {
  return {
    type: "ONBOARDED",
    ...payload,
  }
}

export function tryLoggingUser() {
  return dispatch => {
    if (window.localStorage.getItem(KEY_STORAGE)) {
      dispatch(logInUser(false))
    } else {
      dispatch(disconnected())
    }
  }
}

export function logInUser(redirect) {
  return async dispatch => {
    const response = await fetch("/api/me", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })

    if (response.status === 401) {
      dispatch(logOutUser())
      return
    }

    const user = await response.json()

    window.localStorage.setItem(KEY_STORAGE, true)
    dispatch(connected(user))

    if (redirect) {
      if (!user.acceptedConditions) {
        navigate("/onboarding")
      } else {
        navigate("/installation-notes-v10")
      }
    }
  }
}

export function logOutUser() {
  return async dispatch => {
    window.localStorage.removeItem(KEY_STORAGE)
    await fetch("/api/logout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
    navigate("/")
    dispatch(disconnected())
  }
}
