const ENVIRONMENT = (() => {
  const PIWIK_URL = "matomo.apps.tech.orange"
  const PIWIK_DEV_SITE_ID = "121"
  const PIWIK_INTEGRATION_SITE_ID = "109"
  const PIWIK_PRODUCTION_SITE_ID = "1782"

  if (typeof window === "undefined") {
    // When doing server-side rendering, the environment is local, as we don't want to perform any XHR
    return { name: "ssr", piwik: null, sentry: null }
  } else {
    const hostname = window.location.hostname
    if (
      ![
        // local
        "localhost",
        // integration
        "khiops-integration.apps.fr01.paas.tech.orange",
        // OS4 staging
        "khiops-staging.apps.fr01.paas.tech.orange",
        // OS4 production
        "khiops-production.apps.fr01.paas.tech.orange",
        // OS4 beta for khiops V10
        "khiops-beta.apps.fr01.paas.tech.orange",
        "khiops.tech.orange",
        "khiops.com",
        "www.khiops.com"
      ].includes(hostname)
    ) {
      throw new Error(`Unexpected hostname: ${hostname}`)
    }

    if (hostname === "localhost") {
      return {
        name: "local",
        piwik: { url: PIWIK_URL, siteId: PIWIK_DEV_SITE_ID },
        sentry: {
          dsn:
            "https://5277dff7b01845b1b012d0cd8059e80f@sentry-sae.kermit.itn.intraorange/71",
        },
      }
    } else if (
      [
        "khiops-integration.apps.fr01.paas.tech.orange",
        "khiops-staging.apps.fr01.paas.tech.orange",
      ].includes(hostname)
    ) {
      // Kermit staging or integration
      return {
        name: "integration",
        piwik: {
          url: PIWIK_URL,
          siteId: PIWIK_INTEGRATION_SITE_ID,
        },
        sentry: {
          dsn:
            "https://e04bd059d0294c6aa7c5a54992915ff5@sentry-sae.kermit.itn.intraorange/72",
        },
      }
    } else {
      // Kermit production
      const onInternet =
        hostname === "khiops.tech.orange" ||
        hostname === "www.khiops.com" ||
        hostname === "khiops.com"
      return {
        name: "production",
        piwik: {
          url: PIWIK_URL,
          siteId: PIWIK_PRODUCTION_SITE_ID,
        },
        sentry: onInternet
          ? null // Sentry is not available on internet
          : {
              dsn:
                "https://6183135d55104f6bab4b6836e0d1ebef@sentry-sae.kermit.itn.intraorange/73",
            },
      }
    }
  }
})()

export default ENVIRONMENT
