import { combineReducers } from "redux"
import { combineForms } from "react-redux-form"

import user from "./user"

const initialLoginState = {
  email: "",
}

const initialContactState = {
  firstName: "",
  lastName: "",
  email: "",
  question: "",
  message: "",
  filter: ""
}

const initialRequestLicenseFormState = {
  computerName: "",
  computerId: "",
  khiopsVersion: "10",
}

const initialRequestMultipleLicensesFormState = {
  licensesCommand: "",
  khiopsVersion: "10",
}

const initialPerpetualLicensesSettingsFormState = {
  perpetualLicenses: "",
}

const initialCreateTokenForm = {
  tokenName: "",
}

const initialOnboardingState = {
  firstName: "",
  lastName: "",
  interests: {
    R: false,
    TWE: false,
    BF: false,
    A: false,
    GA: false,
    H: false,
    P: false,
    E: false,
    O: false,
  },
  usageType: "PROFESSIONAL_LICENSE",
  acceptedConditions: false,
}

export default combineReducers({
  requestLicenseForm: combineForms(
    {
      data: initialRequestLicenseFormState,
    },
    "requestLicenseForm",
  ),
  requestMultipleLicensesForm: combineForms(
    { data: initialRequestMultipleLicensesFormState },
    "requestMultipleLicensesForm",
  ),
  perpetualLicensesSettingsForm: combineForms(
    { data: initialPerpetualLicensesSettingsFormState },
    "perpetualLicensesSettingsForm",
  ),
  loginForm: combineForms({ data: initialLoginState }, "loginForm"),
  createTokenForm: combineForms({data: initialCreateTokenForm}, "createTokenForm"),
  onboardingForm: combineForms(
    { data: initialOnboardingState },
    "onboardingForm",
  ),
  contactForm: combineForms({ data: initialContactState }, "contactForm"),
  user,
})
